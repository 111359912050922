import { Component, OnInit } from '@angular/core';
import { MainserviceService } from '../services/mainservice.service'
import { Router,ActivatedRoute,Params } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  statement=""
  constructor(private mainService:MainserviceService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {

    

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const id = params['id'];
      if(id!=undefined && !(this.mainService.fromRoute))
      {
          this.mainService.getDataById(id).subscribe(data=>(
            this.mainService.afterGetDataById(data),
            this.statement=this.mainService.selectedState
            )
            );
      }
      else
        this.statement=this.mainService.selectedState;
    });


  }

}
