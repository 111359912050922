import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { MainserviceService } from '../../services/mainservice.service'
import {FormControl, FormGroupDirective, NgForm, Validators,FormGroup,FormBuilder} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  registrationForm: FormGroup;


  id ="";
  params:Params
  allData;
  x;
  emailAddress="";
  addEmail="";
  userName="";
  preparatoryName="";
  statementsList=[];
  selectedState="";
  showStatementsList=false;

  constructor(private router:Router, private formBuilder: FormBuilder, public mainService:MainserviceService, private activatedRoute:ActivatedRoute) {  }

  ngOnInit(): void {

    this.registrationForm = this.formBuilder.group({
      userName : [null, Validators.required],
      preparatoryName : [null, Validators.required],
      emailAddress : [null, Validators.required],
      selectedState : [null, Validators.required]
    });

    this.statementsList=this.mainService.statements;
    this.selectedState=this.mainService.selectedState;

    this.mainService.isViewMenu=false;

    this.emailAddress=this.mainService.getEmailAddress().emailAddress;
    this.userName=this.mainService.getEmailAddress().userName;
    this.preparatoryName=this.mainService.getEmailAddress().preparatoryName;

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const id = params['id'];
      if(id!=undefined)
      {
        this.mainService.getDataById(id).subscribe(data=>(
        this.mainService.isEditMap=true,
        this.mainService.idForEdit=id,
        this.mainService.linkForEdit= this.mainService.currUrl +"?id="+id,
        this.mainService.afterGetDataById(data)
          
        ));
       
        
    
      }
        

    });
    if(this.mainService.userDetails.userName!="")
      this.userName=this.mainService.userDetails.userName;

  }

  onFormSubmit(){
    if(this.mainService.isEditMap==false)
    {
      this.mainService.userDetails={"emailAddress":this.emailAddress,"userName":this.userName,"preparatoryName":this.preparatoryName,"addEmail":""}
      this.mainService.selectedState=this.registrationForm.controls["selectedState"].value;
    }

 this.mainService.isViewMenu=true;
 this.mainService.route('goals');
//  this.router.navigate(['/goals']);

  }

  onClick(state)
  {
    this.selectedState=state;
    this.mainService.selectedState=state;
  }

  clickStatementsList()
  {
    if(this.showStatementsList==false)
      this.showStatementsList=true;
    else
      this.showStatementsList=false;
  }

}
