
<div style="text-align: center;" >
        <img src="assets/img/homePage.jpg" style=" float:right; padding-right: 80px; padding-top: -260px;"><br><br>
        <h1 style="color: rgb(84, 190, 216); position: relative;"  class="glow">מפת מנהיגות אישית לשנת מכינה</h1> <br><br>
       
        <form [formGroup]="registrationForm" (ngSubmit)="onFormSubmit()">
        <div #personalDetails *ngIf="mainService.isEditMap==false">
                <mat-form-field >
                        <mat-label>שם פרטי</mat-label>
                        <input formControlName="userName" [errorStateMatcher]="matcher" matInput  style="width:250px;" [(ngModel)]="userName" >
                         <mat-error>
                                <span *ngIf="!registrationForm.get('userName').valid && registrationForm.get('userName').touched">נא הזן שם פרטי</span>
                        </mat-error>
                </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
                <mat-form-field >
                        <mat-label>שם מכינה</mat-label>
                        <input formControlName="preparatoryName" [errorStateMatcher]="matcher" matInput   style="width:250px;" [(ngModel)]="preparatoryName">
                        <mat-error>
                                <span *ngIf="!registrationForm.get('preparatoryName').valid && registrationForm.get('preparatoryName').touched">נא הזן שם מכינה</span>
                        </mat-error>
                </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
                <mat-form-field >
                        <mat-label>כתובת מייל</mat-label>
                        <input formControlName="emailAddress" [errorStateMatcher]="matcher" matInput   style="width:250px;" [(ngModel)]="emailAddress">
                        <mat-error>
                                <span *ngIf="!registrationForm.get('emailAddress').valid && registrationForm.get('emailAddress').touched">נא הזן כתובת מייל</span>
                        </mat-error>
                </mat-form-field>
               
               <br><br>
               <button type="button" (click)="clickStatementsList()" mat-stroked-button style="height: 55px;width: 330px; font-size: 18px;" >
        מנהיגות בשבילי זה...  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;>>></button><br>

        <mat-form-field style="width:600px;" *ngIf="showStatementsList">
         
                <input type="text"
                        placeholder="בחר/י היגד או כתוב/כתבי את המשפט שלך"
                        aria-label="Number"
                        matInput
                        formControlName="selectedState"
                        [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                        <mat-option *ngFor="let stat of statementsList" (click)="onClick(stat.state)" [value]="stat.state">
                                {{stat.state}}
                        <br>
                        </mat-option>

                </mat-autocomplete>
                <mat-error>
                        <span *ngIf="!registrationForm.get('selectedState').valid && registrationForm.get('selectedState').touched"></span>
                </mat-error>
        </mat-form-field> 

        </div>
        <br><h3 style="color: rgb(84, 190, 216); "  *ngIf="mainService.isEditMap==true">שלום {{mainService.userDetails.userName}},</h3> <br *ngIf="mainService.isEditMap==true">
        <br *ngIf="mainService.isEditMap==false"><button  type="submit" [disabled]="!(registrationForm.valid || mainService.isEditMap==true)" mat-stroked-button style="height: 45px;width: 200px; font-size: 16px; position: relative;"  >כניסה למפה</button><br>
        </form>
        <br><br><div style="display: inline-block;bottom:55px;padding-top: 10px;  "><h5 style="line-height:normal; ">"כל אחד ראוי שישאל את עצמו את השאלה הבאה בפשטות ובישירות: <br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  מהן תכונותי האישיות שאותן אני יכול להפוך לתכונות של מנהיג?"  דייל קרנג'י</h5></div>
</div>