<button mat-stroked-button class="hideInPrint" (click)="onPrint()" style="margin-left:15px;">
  <img style="width:25px;" src="assets/img/icons8-printer-100.png">
</button> 
<button mat-stroked-button class="hideInPrint" (click)="saveData()" style="margin-left:100px; ">
  <img style="width:25px;" src="assets/img/icons8-save-100.png">
</button> 

<div style="padding-top: 10px;" class="">
<table   id="print-section" style="float:right;width:100%; " #tblActions mat-table editable [dataSource]="actions" class="example-table mat-elevation-z8" >
   
    <!-- actionToDo Column -->
<ng-container style="width:40%;"  matColumnDef="actionToDo">
      <th mat-header-cell *matHeaderCellDef>פעולה לביצוע</th>
      <td mat-cell *matCellDef="let row" style="text-align:right;">
              <mat-form-field style="width:90%;">
                  <textarea  style="height:20px;" matInput 
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"  [(ngModel)]="row.actionToDo" ></textarea>
               </mat-form-field> 
      </td>
</ng-container>

    <!-- destDate Column -->
<ng-container matColumnDef="destDate">
  <th mat-header-cell *matHeaderCellDef>תאריך יעד</th>
  <td mat-cell *matCellDef="let row" style="text-align:right;">
        <mat-form-field>
            <!-- <input   matInput   > -->
            <!-- <mat-label>Choose a date</mat-label> -->
            <input  matInput  [matDatepicker]="picker" [(ngModel)]="row.destDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> 
  </td>
</ng-container>

  <!-- responsible Column -->
  <ng-container matColumnDef="responsible">
    <th mat-header-cell *matHeaderCellDef>באחריות מי לבצע</th>
    <td mat-cell *matCellDef="let row" style="text-align:right;">
          <mat-form-field>
              <input   matInput  [(ngModel)]="row.responsible" >
           </mat-form-field> 
    </td>
  </ng-container>

  <!-- isDone Column -->
  <ng-container matColumnDef="isDone">
        <th mat-header-cell *matHeaderCellDef>בוצע</th>
        <td mat-cell *matCellDef="let row" style="text-align:right;">
                <mat-checkbox color="primary"  [(ngModel)]="row.isDone"></mat-checkbox> 
        </td>
      </ng-container>
  


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>

  </table>
</div>
