import { Component, OnInit ,OnDestroy, ViewChild, HostListener, AfterContentInit, AfterViewInit} from '@angular/core';
import { Router,ActivatedRoute,Params, NavigationEnd } from '@angular/router';
import { MainserviceService } from '../../services/mainservice.service';
import {MatSidenavContainer} from '@angular/material/sidenav';

@Component({
  selector: 'app-personal-prop',
  templateUrl: './personal-prop.component.html',
  styleUrls: ['./personal-prop.component.css']
})
export class PersonalPropComponent implements OnInit,OnDestroy {
  // @ViewChild('sidenav') sidenav: MatSidenav;

  subjects: Array<{id:number, subjectName:string}>;
  selectedItem:number = 0;

  // subjects: Array<{id:number, subjectName:string, personalProp:number,support:string,actionToDo:string}>;

//   @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
//     console.log("cancel before unload...");
//     // Do more processing...
//     event.returnValue = false;
// }



  constructor(private router:Router,private activatedRoute:ActivatedRoute, private mainService: MainserviceService) 
  { 
    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });
  }

  ngOnDestroy(): void {
    console.log("destroy");
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const id = params['id'];
      if(id!=undefined)
      {
        this.mainService.isEditMap=true,
        this.mainService.idForEdit=id,
        this.mainService.linkForEdit= this.mainService.currUrl +"?id="+id
        
       
        if(!(this.mainService.fromRoute))
        {
          this.mainService.getDataById(id).subscribe(data=>(
          this.mainService.afterGetDataById(data),
          this.getSubjects(),
          this.loadSubject('0')
          // this.subInAreasOfLife = this.mainService.getSubById(subId),
          // this.subjectName=this.mainService.getSubNameById(subId)
            )
            );
        }
        else
        {
          this.getSubjects();
          this.loadSubject('0');
        }

      }
      else
      {
        this.getSubjects();
        this.loadSubject('0');
      }
    });



   
  }

  getSubjects(){
    this.subjects = this.mainService.getAll();
  }

  loadSubject(id: string){
      this.mainService.route('/prop/'+id);
    // this.router.navigate(['/prop',id]);
  }

  moveToActions()
  {
    this.mainService.moveToActions();
    this.mainService.route('actions')
    // this.router.navigate(['/actions']);
  }
 
  saveData()
  {
    this.mainService.saveAllDataInDB();
  }

  selectionChanged(e,i) {
    this.selectedItem = i
  }

  
  onPrint() {
    window.print();
 }

}
