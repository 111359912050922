import { Component, OnInit } from '@angular/core';
import { MainserviceService } from '../../services/mainservice.service'
import { Router,ActivatedRoute,Params } from '@angular/router';


@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnInit {

  goals;
  displayedColumns: string[] = ['goal'];
  statementsList=[];
  selectedState="";
  constructor(public mainService:MainserviceService,private router:Router,private activatedRoute:ActivatedRoute) {

    this.goals=this.mainService.getGoals();
    console.log('constructor goals');

    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });
   }

  ngOnInit(): void {
    console.log('init goals');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const id = params['id'];
      if(id!=undefined )
      {
        this.mainService.isEditMap=true,
        this.mainService.idForEdit=id,
        this.mainService.linkForEdit= this.mainService.currUrl +"?id="+id

        if(!(this.mainService.fromRoute))
        {
          this.mainService.getDataById(id).subscribe(data=>(
        
            this.mainService.afterGetDataById(data),
             this.goals=this.mainService.goals
            )
            );
        }
        else
          this.goals=this.mainService.goals;
        
        
        
    
      }
    });

    // this.statementsList=this.mainService.statements;
    // this.selectedState=this.mainService.selectedState;


  }

  saveData()
  {
    this.mainService.saveAllDataInDB();
  }

  nextPage()
  {
    this.mainService.route('proplist');
    // this.router.navigate(['/proplist']);
  }

  createGoal()
  {
    if(this.goals.length>0 && this.goals[this.goals.length-1].goal!="")
    {
      this.mainService.addGoal();
      this.goals= this.mainService.getGoals().concat();
    }
   
  }

  // onClick(state)
  // {
  //   this.selectedState=state;
  //   this.mainService.selectedState=state;
  // }

  onPrint() {
    window.print();
 }
}
