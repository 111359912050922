import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { MainserviceService } from './services/mainservice.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
linkForEdit="";
// isViewMenu=false;
  constructor(public router: Router,public mainservice:MainserviceService,public activatedRoute:ActivatedRoute) {
    

  }

  ngOnInit(): void {
    // this.isViewMenu=this.mainservice.isViewMenu;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const id = params['id'];
      if(id!=undefined)
      {
        this.linkForEdit = this.mainservice.currUrl +"?id="+id;
      }
        

    });

  }
route(routeToPage)
{
  this.mainservice.route(routeToPage);
  // this.mainservice.isEditMap ? this.router.navigate(['/'+routeToPage,{ id: this.mainservice.idForEdit }])
  // : this.router.navigate(['/'+routeToPage]);
}


}
