import { Injectable,Injector,OnDestroy  } from '@angular/core';
import { HttpInterceptor,HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Subscription, timer,interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EmailDialogComponent } from '../email-dialog/email-dialog.component';
import { Location } from '@angular/common';
import { state } from '@angular/animations';
import { ɵINTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'string' })
};

@Injectable({
  providedIn: 'root'
})
export class MainserviceService implements OnDestroy {

  ngOnDestroy(): void{
    this.subscription.unsubscribe();
  }

  constructor(private http:HttpClient,private router:Router,public dialog: MatDialog,private location: Location) {
    
   }

   subscription: Subscription;
  isViewMenu=true;
  isEditMap=false;
  idForEdit="";
  linkForEdit="";
  fromRoute=false;
  allData;
  currUrl="https://shibolim.rachip.com/";
  strAllData="";

  userDetails={emailAddress:"",userName:"",preparatoryName:"",addEmail:""};

  private subjects = [
    {id: 0, subjectName: 'כל תחומי החיים',img:'assets/img/propList.png'},
    {id: 1, subjectName: 'מגורים וחיי יום יום',img:'assets/img/residence.png'},
    {id: 2, subjectName: 'תחום חברתי',img:'assets/img/social.png'},
    {id: 3, subjectName: 'הכנה לצבא',img:'assets/img/Army.png'},
    {id: 4, subjectName: 'תחום בריאות',img:'assets/img/healthy.png'},
    {id: 5, subjectName: 'טיולים',img:'assets/img/trips.png'},
    {id: 6, subjectName: 'לימודים',img:'assets/img/Studies.png'},
    {id: 7, subjectName: 'התנדבות בקהילה',img:'assets/img/volunteering.png'},
  ];

  statements=[
    {id: 1, state: '"'+'אל תלך בעקבות ההמון, הנח להמון ללכת בעקבותיך'+'"'+", מרגרט תאצ'ר"},
    {id: 2, state: '"'+'מנהיג הוא במיטבו כאשר אנשיו בקושי יודעים שהוא קיים… כאשר עבודתו נעשית, מטרתו מוגשמת, הם כולם יגידו: תראו מה השגנו בעצמנו!'+'"'+' לאו צה'},
    {id: 3, state: '"'+'מנהיג אמיתי לא מחפש הסכמה כללית, אלא מעצב אותה בעצמו.'+'"'+' מרטין לותר קינג'},
    {id: 4, state: '"'+'מִי הָאִישׁ הֶחָפֵץ חַיִּים? אֹהֵב יָמִים, לִרְאוֹת טוֹב. נְצֹר לְשׁוֹנְךָ מֵרָע, וּשְׂפָתֶיךָ מִדַּבֵּר מִרְמָה. סוּר מֵרָע וַעֲשֵׂה טוֹב, בַּקֵּשׁ שָׁלוֹם וְרָדְפֵהוּ.'+'"'+'(דוד המלך. תהילים לד)'},
    {id: 5, state: '"'+'היה השינוי שאתה רוצה לראות בעולם.'+'"'+' מהטמה גנדי'},
    {id: 6, state: '"'+'שינוי לא יבוא אם נחכה לאדם אחר או זמן אחר. אנחנו האדם שחיכינו לו. אנחנו השינוי שאנו מחפשים'+'"'+' ברק אובאמה.'},
    {id: 7, state: '"'+'כוח לא מגיע ממה שאתה יכול לעשות. הוא מגיע מלעשות את הדברים שפעם חשבת שאתה לא יכול לעשות.'+'"'+" ריקי רוג'רס"},
    {id: 8, state: '"'+'ההבדל בין מי שאתה לבין מי שאתה רוצה להיות הוא מה שאתה עושה.'+'"'+' לא ידוע.'},
    {id: 9, state: '"'+'אם הפעולות שלך נותנות השראה לאחרים לחלום יותר, ללמוד יותר, לעשות יותר ולהיות יותר ממה שהם, אז אתה מנהיג.'+'"'+" ג'ון קווינסי אדמס"},
    {id: 10, state: '"'+'מנהיג צריך לקחת אחריות ולא להמציא תירוצים.'+'"'},
    {id: 11, state: '"'+'על מנת להנהיג אנשים – לך מאחוריהם.'+'"'+' לאו צה'}
  ];

  selectedState="";

   subInAreasOfLife: Array<{id:number, subject:number,personalPropID:number,personalPropType:number,personalProp:string,support:string,actionToDo:string,isUniqueCharacteristics:boolean}>=[];

   actions: Array<{id:number, actionToDo:string,destDate:string,responsible:string,isDone:boolean}>=[];

   goals: Array<{id:number,goal:string}>=[{id:1,goal:""}];

  strengths=[
    {id: 1,property: '',subjects:new Array()}
    ];

  weaknesses=[
    {id: 1,property: '',subjects:new Array()}
    ];

  uniqueCharacteristics=[
    {property: '',subjects:new Array()}
    ];

  getEmailAddress()
  {
return this.userDetails;
  }

  getGoals()
  {
    return this.goals;
  }

  addGoal()
  {
    this.goals.push({id:this.goals[this.goals.length-1].id+1,goal:""});
  }

  route(routeToPage)
  {
    this.fromRoute=true;
    this.isEditMap ? this.router.navigate(['/'+routeToPage], { queryParams: { id: this.idForEdit }} )
    : this.router.navigate(['/'+routeToPage]);
  }

  // updateOnTimer(allData){
  //   var res1 ;
  //   this.http.post(environment.baseUrl+"update&id="+this.idForEdit,JSON.stringify(allData),{responseType: 'text'})
  //         .subscribe(res => res1=res);
  // }

  saveAllDataInDB()
  {
    let allData;
    let emailData={};
    allData={"userDetails":this.userDetails,"statement":this.selectedState,"goals":this.goals,"strengths":this.strengths,"weaknesses":this.weaknesses,"uniqueCharacteristics":this.uniqueCharacteristics,"subInAreasOfLife":this.subInAreasOfLife,"actions":this.actions};
    if(this.isEditMap==false)
    {
        this.http.post(environment.baseUrl+"create",JSON.stringify(allData) ,{responseType: 'text'})
      .subscribe(res => {
        if(res!="")
        {
          this.idForEdit=res,
          this.linkForEdit=this.currUrl +"?id="+res;
          

          emailData={emailTo: this.userDetails.emailAddress,userName:this.userDetails.userName,preparatoryName:this.userDetails.preparatoryName,linkToEdit: this.linkForEdit,emailTo2:this.userDetails.addEmail,firstSaving:true};
          this.http.post(environment.baseUrl+"sendEmail",JSON.stringify(emailData) ,{responseType: 'text'})
          .subscribe(res2 => {
              alert("נשמר בהצלחה! \r\n ברגע זה נשלח אליך מייל עם קישור למפת המנהיגות האישית שלך... ")
         
               //window.location.href= window.location.href + "/?id="+res;

              this.location.replaceState(location.pathname +'?id='+res);
               this.isEditMap=true;
          });

          if(this.subscription==undefined)
          {
            this.subscription = interval(10000).pipe(
              switchMap(() => this.http.post(environment.baseUrl+"update&id="+this.idForEdit,JSON.stringify(allData),{responseType: 'text'}))
            ).subscribe(result =>console.log("saved"));        
          }
        }
        else
        {
          alert("השמירה נכשלה");
        }
        
      }, (err: any) => {
        console.log("err: "+err)
      });
    }
    else
    {

      const dialogRef = this.dialog.open(EmailDialogComponent, {
        width: '420px',
        height: '200px',
        direction:"rtl"
      });

      dialogRef.afterClosed().subscribe(result => {
          this.http.post(environment.baseUrl+"update&id="+this.idForEdit,JSON.stringify(allData),{responseType: 'text'})
          .subscribe(res => {
            if(res=="1")
            {
              if(this.userDetails.addEmail!="")
              {
                emailData={emailTo: this.userDetails.addEmail,userName:this.userDetails.userName,preparatoryName:this.userDetails.preparatoryName,linkToEdit: this.linkForEdit,firstSaving:false};
                this.http.post(environment.baseUrl+"sendEmail",JSON.stringify(emailData) ,{responseType: 'text'})
                .subscribe(res2 => {
                    alert("נשמר בהצלחה! נשלח מייל לכתובת הנוספת ")
                });
              }
              else
              {
                alert("נשמר בהצלחה!");
              }
            
            }
            else
            {
             alert("השמירה נכשלה");
            }
           
         }, (err: any) => {
           console.log("err: "+err)
         });
      
      });


      
    }
    
  }

  

  public getDataById(id:string)
  {
    this.fromRoute=false;
    this.isEditMap=true;
    return this.http.get(environment.baseUrl+"get&id="+id,{responseType: 'text'});

  }

  afterGetDataById(data){
    // data=data.replace(/\\/g, '\\\\');
    data = data.split("\n").join("  ");
    
    this.allData=JSON.parse(data);
    this.isEditMap=true;
    this.userDetails=this.allData.userDetails;
    this.selectedState=this.allData.statement;
    this.goals=this.allData.goals;
    this.strengths=this.allData.strengths;
    this.weaknesses=this.allData.weaknesses;
    this.uniqueCharacteristics=this.allData.uniqueCharacteristics;
    this.subInAreasOfLife=this.allData.subInAreasOfLife;
    this.actions=this.allData.actions;

    if(this.subscription==undefined)
    {
      this.subscription = interval(10000).pipe(
        switchMap(() => this.http.post(environment.baseUrl+"update&id="+this.idForEdit,JSON.stringify(this.allData),{responseType: 'text'}))
      ).subscribe(result =>{
        console.log(new Date().getHours() + ':' + new Date().getMinutes() + ':'+  new Date().getSeconds()),
        console.log("saved")});
    }
  }

  moveToActions()
  {
    this.subInAreasOfLife.forEach(sub=>{
      if(sub.actionToDo!='' && this.actions.filter(a=>a.actionToDo==sub.actionToDo).length==0)
      {
        this.actions.push({id:(this.actions.length==0?1:this.actions[this.actions.length-1].id+1),actionToDo:sub.actionToDo,destDate:'',responsible:'',isDone:false});
      }
    });
  }

  //functions for personal properties list

  moveToAreasOfLife()
  {
    this.strengths.forEach(str=> {
      str.subjects.forEach(s=>{
        if(s!="בחר הכל" && s!="")
        {
          let subId=this.subjects.filter(subj=>subj.subjectName==s)[0].id;
          if(this.subInAreasOfLife.filter(sub=>sub.personalPropID==str.id && sub.personalPropType==1 && sub.subject==subId).length==0)
          {
            this.subInAreasOfLife.push({id:(this.subInAreasOfLife.length==0?1:this.subInAreasOfLife[this.subInAreasOfLife.length-1].id+1),subject:subId,personalProp:str.property,personalPropID:str.id,personalPropType:1,support:'',actionToDo:'',isUniqueCharacteristics:false});
          }
        }
        
      });
    });
    this.weaknesses.forEach(str=> {
      str.subjects.forEach(s=>{
        if(s!="בחר הכל"&& s!="")
        {
          let subId=this.subjects.filter(subj=>subj.subjectName==s)[0].id;
          if(this.subInAreasOfLife.filter(sub=>sub.personalPropID==str.id && sub.personalPropType==2 && sub.subject==subId).length==0)
          {
            this.subInAreasOfLife.push({id:(this.subInAreasOfLife.length==0?1:this.subInAreasOfLife[this.subInAreasOfLife.length-1].id+1),subject:subId,personalProp:str.property,personalPropID:str.id,personalPropType:2,support:'',actionToDo:'',isUniqueCharacteristics:false});
          }
        }
      });
    });
    // this.uniqueCharacteristics.forEach(str=> {
    //   str.subjects.forEach(s=>{
    //     if(s!="בחר הכל"&& s!="")
    //     {
    //       let subId=this.subjects.filter(subj=>subj.subjectName==s)[0].id;
    //       if(this.subInAreasOfLife.filter(sub=>sub.personalProp==str.property && sub.subject==subId).length==0)
    //       {
    //         this.subInAreasOfLife.push({id:(this.subInAreasOfLife.length==0?1:this.subInAreasOfLife[this.subInAreasOfLife.length-1].id+1),subject:subId,personalProp:str.property,support:'',actionToDo:'',isUniqueCharacteristics:true});
    //       }
    //     }
    //   });
    // });
  }

  // Strengths
  getStrengths():Array<{id:number,property:string,subjects:string[]}>
  {
    return this.strengths;
    
  }

  addStrength()
  {
      this.strengths.push({id:(this.strengths[this.strengths.length-1].id)+1,property:'',subjects:[""]});
      console.log(JSON.stringify(this.strengths));
  }

  // addSubToStrengths(prop:string,subj:string){
  //   this.strengths.forEach(str=> {
  //     str.property==prop && !(str.subjects.includes(subj))? str.subjects.push(subj):0;
  //   });
  // }
  addSubToStrengths(id:number,subj:string){
    this.strengths.forEach(str=> {
      str.id==id && !(str.subjects.includes(subj))? str.subjects.push(subj):0;
    });
  }


  // updateSubToStrengths(prop:string,subj:string[])
  // {
  //   this.strengths.forEach(str=> {
  //     str.property==prop ? str.subjects=subj:0;
  //   });
  // }
  updateSubToStrengths(id:number,subj:string[])
  {
    this.strengths.forEach(str=> {
      str.id==id ? str.subjects=subj:0;
    });
  }

  // delSubToStrengths(prop:string,subj:string){
  //   this.strengths.forEach(str=> {
  //     str.property==prop ?  str.subjects.splice(str.subjects.indexOf(subj), 1):0;
  //   });
  // }
  delSubToStrengths(id:number,subj:string){
    this.strengths.forEach(str=> {
      str.id==id ?  str.subjects.splice(str.subjects.indexOf(subj), 1):0;
    });
  }
  
  // Weaknesses
  getWeaknesses():Array<{id:number,property:string,subjects:string[]}>
  {
    return this.weaknesses;
  }

  addWeakness()
  {
      this.weaknesses.push({id:(this.weaknesses[this.weaknesses.length-1].id)+1,property:'',subjects:[""]});
  }

  // addSubToWeaknesses(prop:string,subj:string){
  //   console.log("prop: "+prop+ " subj: "+subj);
  //   this.weaknesses.forEach(we=> {
  //     we.property==prop && !(we.subjects.includes(subj))? we.subjects.push(subj):0;
  //   });
  // }
  addSubToWeaknesses(id:number,subj:string){
    this.weaknesses.forEach(we=> {
      we.id==id && !(we.subjects.includes(subj))? we.subjects.push(subj):0;
    });
  }

  // updateSubToWeaknesses(prop:string,subj:string[])
  // {
  //   this.weaknesses.forEach(we=> {
  //     we.property==prop ? we.subjects=subj:0;
  //   });
  // }
  updateSubToWeaknesses(id:number,subj:string[])
  {
    this.weaknesses.forEach(we=> {
      we.id==id ? we.subjects=subj:0;
    });
  }

  // delSubToWeaknesses(prop:string,subj:string){
  //   this.weaknesses.forEach(we=> {
  //     we.property==prop ?  we.subjects.splice(we.subjects.indexOf(subj), 1):0;
  //   });
  // }
  delSubToWeaknesses(id:number,subj:string){
    this.weaknesses.forEach(we=> {
      we.id==id ?  we.subjects.splice(we.subjects.indexOf(subj), 1):0;
    });
  }

  // UniqueCharacteristics
  getUniqueCharacteristics():Array<{property:string,subjects:string[]}>
  {
    return this.uniqueCharacteristics;
  }
  addUniqueCharacteristics()
  {
      this.uniqueCharacteristics.push({property:'',subjects:[""]});
  }

  addSubToUniqueChara(prop:string,subj:string){
    this.uniqueCharacteristics.forEach(uni=> {
      uni.property==prop && !(uni.subjects.includes(subj))? uni.subjects.push(subj):0;
    });
  }

  updateSubToUniqueChara(prop:string,subj:string[])
  {
    this.uniqueCharacteristics.forEach(uni=> {
      uni.property==prop ? uni.subjects=subj:0;
    });
  }

  delSubToUniqueChara(prop:string,subj:string){
    this.uniqueCharacteristics.forEach(uni=> {
      uni.property==prop ?  uni.subjects.splice(uni.subjects.indexOf(subj), 1):0;
    });
  }
  


  // functions for subjects
  getAll(){
    return this.subjects;
  }

  getSubById(subId: number)
  {
    let filterArr=[];
    if(subId==0)
    {
      this.subInAreasOfLife.forEach(s=> {
        // if(filterArr==undefined)
        //   filterArr.push(s);
        // else 
          if(filterArr.filter(f=>(f.personalPropID==s.personalPropID && f.personalPropType==s.personalPropType)).length==0)
          filterArr.push(s);
      });

    }
    else
      filterArr= this.subInAreasOfLife.filter(s=>s.subject==subId);
    return filterArr;

  }

  getSubNameById(id: number):string
  {
    let filterArr= this.subjects.filter(s=>s.id==id);
    return filterArr[0].subjectName;
  }
  
  getActions()
  {
    return this.actions;
  }

}
