
  <div  style="width:100%;float:right; height: 60px;">
      <img src="assets/img/logo.png"  style="float: left; position: static; height:110px;left:0;"  />
   
    <!-- <span style="margin-top: 15px; position:relative; color: #673ab7; font-size: 11px; padding-top: 20px; padding-left: 5px;" *ngIf="mainservice.isEditMap">לדף הבית: &nbsp;</span>  -->
    <!-- <a href="{{linkForEdit}}" *ngIf="mainservice.isEditMap" style="color:rgb(84, 190, 216); float: left; position: inherit;height:12px; font-size: 11px; padding-top: 15px; margin-left:120px;"><span style=" color: #673ab7; font-size: 11px; margin-left: 5px;">לדף הבית: </span> {{linkForEdit}}</a> -->
<nav *ngIf="mainservice.isViewMenu"  mat-tab-nav-bar style="position: absolute;"> 
       <!-- mat-align-tabs="center" -->
       
       <a mat-tab-link *ngIf="mainservice.isEditMap"
       (click)="route('/home')"
       routerLinkActive #rla0="routerLinkActive"
       [active]="rla0.isActive || router.url.includes('/home') "
      >
      לדף הבית: &nbsp; {{mainservice.linkForEdit}}
     </a>

  <a mat-tab-link 
    (click)="route('goals')"
    routerLinkActive #rla1="routerLinkActive"
    [active]="rla1.isActive || router.url.includes('/goals') "
   ><img src="assets/img/goals.png"  class="img-input"  width="30px;"  />
    מטרות לשנת המכינה
  </a>

  <a mat-tab-link 
  (click)="route('proplist')"
    routerLinkActive #rla2="routerLinkActive"
    [active]="rla2.isActive || router.url.includes('/proplist')"
    ><img src="assets/img/propList.png"  class="img-input"  width="30px;"  />
    מאפיינים אישיים
  </a>
  
  <a mat-tab-link 
   (click)="mainservice.moveToAreasOfLife(); route('prop/0')"    
    routerLinkActive #rla3="routerLinkActive"
    [active]="rla3.isActive || router.url.includes('/prop/')"
    ><img src="assets/img/healthy.png"  class="img-input"  width="30px;"  />
    תחומי חיים
  </a>

  <a mat-tab-link 
  (click)="mainservice.moveToActions(); route('actions')"
    routerLinkActive #rla4="routerLinkActive"
    [active]="rla4.isActive || router.url.includes('/actions')"
    ><img src="assets/img/actions.png"  class="img-input"  width="30px;"  />
    פעולות לביצוע
  </a>
  
  </nav>
  </div>
  
  <app-header *ngIf="mainservice.isViewMenu"    ></app-header> 

  <router-outlet style="top: -10px; margin-right: 20px;"></router-outlet>

   <app-footer   class="mt-auto"></app-footer> 

             





<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
    :host {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      color: #333;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 8px 0;
    }
  
    p {
      margin: 0;
    }
  
    .spacer {
      flex: 1;
    }
  
    .toolbar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 60px;
      display: flex;
      align-items: center;
      background-color: #1976d2;
      color: white;
      font-weight: 600;
    }
  
    .toolbar img {
      margin: 0 16px;
    }
  
    .toolbar #twitter-logo {
      height: 40px;
      margin: 0 16px;
    }
  
    .toolbar #twitter-logo:hover {
      opacity: 0.8;
    }
  
    .content {
      display: flex;
      margin: 82px auto 32px;
      padding: 0 16px;
      max-width: 960px;
      flex-direction: column;
      align-items: center;
    }
  
    svg.material-icons {
      height: 24px;
      width: auto;
    }
  
    svg.material-icons:not(:last-child) {
      margin-right: 8px;
    }
  
  
  
    svg#rocket {
      width: 80px;
      position: absolute;
      left: -10px;
      top: -24px;
    }
  
    svg#rocket-smoke {
      height: calc(100vh - 95px);
      position: absolute;
      top: 10px;
      right: 180px;
      z-index: -10;
    }
  
    a,
    a:visited,
    a:hover {
      color: #673ab7;
      text-decoration: none;
    }
  
    a:hover {
      color: #673ab7;
    }
  
    .terminal {
      position: relative;
      width: 80%;
      max-width: 600px;
      border-radius: 6px;
      padding-top: 45px;
      margin-top: 8px;
      overflow: hidden;
      background-color: rgb(15, 15, 16);
    }
  
    .terminal::before {
      content: "\2022 \2022 \2022";
      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
      background: rgb(58, 58, 58);
      color: #c2c3c4;
      width: 100%;
      font-size: 2rem;
      line-height: 0;
      padding: 14px 0;
      text-indent: 4px;
    }
  
    .terminal pre {
      font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
      color: white;
      padding: 0 1rem 1rem;
      margin: 0;
    }
  
    .circle-link {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      margin: 8px;
      background-color: white;
      border: 1px solid #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: 1s ease-out;
    }
  
    .circle-link:hover {
      transform: translateY(-0.25rem);
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    }
  
    footer {
      margin-top: 8px;
      display: flex;
      align-items: center;
      line-height: 20px;
    }
  
    footer a {
      display: flex;
      align-items: center;
    }
  
    .github-star-badge {
      color: #24292e;
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 3px 10px;
      border: 1px solid rgba(27,31,35,.2);
      border-radius: 3px;
      background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
      margin-left: 4px;
      font-weight: 600;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    }
  
    .github-star-badge:hover {
      background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
      border-color: rgba(27,31,35,.35);
      background-position: -.5em;
    }
  
    .github-star-badge .material-icons {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  
    svg#clouds {
      position: fixed;
      bottom: -160px;
      left: -230px;
      z-index: -10;
      width: 1920px;
    }
  
  
    /* Responsive Styles */
    @media screen and (max-width: 767px) {
  
      .card-container > *:not(.circle-link) ,
      .terminal {
        width: 100%;
      }
  
      .card:not(.highlight-card) {
        height: 16px;
        margin: 8px 0;
      }
  
      .card.highlight-card span {
        margin-left: 72px;
      }
  
      svg#rocket-smoke {
        right: 120px;
        transform: rotate(-5deg);
      }
    }
  
    @media screen and (max-width: 575px) {
      svg#rocket-smoke {
        display: none;
        visibility: hidden;
      }
    }
    .container {
      padding: 20px;
    }
  </style>
