            <div class="wrapper">
                <div  class="contentDiv">
                        <img style="height: 50px; " src="assets/img/Scuncil.png"  />
                        <div class="verticalLine" ></div>
                        <img class="footerImg" style="height: 35px;  " src="assets/img/JDC.png"  />
                        <div class="verticalLine" ></div>
                        <img class="footerImg" src="assets/img/Ruderman.png"  />
                        <div class="verticalLine" ></div>
                        <img class="footerImg"  src="assets/img/NewYork.PNG" />
                        <div class="verticalLine" ></div>
                        <img class="footerImg "  src="assets/img/Funds.jpg"  />
                        <br style="display: none;" class="showInPrint">
                        <div class="verticalLine HideInPrint" ></div>
                        <img class="footerImg"  src="assets/img/MinistryOfHealth.jpg"  />
                        <div class="verticalLine" ></div>
                        <img class="footerImg"  src="assets/img/SocialSecurityWing.jpg"  />
                        <div class="verticalLine" ></div>
                        <img class="footerImg"  style="height: 40px; " src="assets/img/SwelfareL.png"  />
                        <div class="verticalLine" ></div>
                        <img class="footerImg"  src="assets/img/Education.png"  />
                        <div class="verticalLine" ></div>
                        <img class="footerImg"  src="assets/img/Society.png"  />
                        
                        
                </div>
            </div>
                