<table style="float:right;width:100%; "  #tblAreasOfLife mat-table editable 
[dataSource]="id.toString()==='1'?this.mainService.getSubById(1):subInAreasOfLife" class="example-table mat-elevation-z8" >
       
    <!-- personal property Column -->
<ng-container matColumnDef="personalProp">
      <th mat-header-cell *matHeaderCellDef>מאפיין אישי</th>
      <td mat-cell *matCellDef="let row" style="text-align:right;">
              <mat-form-field>
                  <textarea [disabled]="id=='0'? true: false"  matInput 
                  cdkTextareaAutosize style="box-sizing:content-box;"
                  cdkAutosizeMinRows="1"  [(ngModel)]="row.personalProp"></textarea>
               </mat-form-field> 
      </td>
</ng-container>

    <!-- support Column -->
<ng-container matColumnDef="support">
  <th mat-header-cell *matHeaderCellDef>צורך בתמיכה או התאמה - פירוט</th>
  <td mat-cell *matCellDef="let row" style="text-align:right;">
        <mat-form-field>
            <textarea   matInput [disabled]="id=='0'? true: false"
            cdkTextareaAutosize style="box-sizing:content-box;"
            cdkAutosizeMinRows="1"  [(ngModel)]="row.support" ></textarea>
         </mat-form-field> 
  </td>
</ng-container>

  <!-- actionToDo Column -->
  <ng-container matColumnDef="actionToDo">
    <th mat-header-cell *matHeaderCellDef>פעולה לביצוע</th>
    <td mat-cell *matCellDef="let row" style="text-align:right;">
          <mat-form-field  style="width:90%;">
              <textarea   matInput [disabled]="id=='0'? true: false"
            cdkTextareaAutosize style="box-sizing:content-box;"
            cdkAutosizeMinRows="1"  [(ngModel)]="row.actionToDo" ></textarea>
           </mat-form-field> 
    </td>
  </ng-container>
  


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'change-color': row.isUniqueCharacteristics }"></tr>
    
  </table>
