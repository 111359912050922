
<button mat-stroked-button class="hideInPrint" (click)="onPrint()" style="margin-left:15px;">
        <img style="width:25px;" src="assets/img/icons8-printer-100.png">
</button> 
<button mat-stroked-button class="hideInPrint" (click)="saveData()" style="margin-left:100px; ">
        <img style="width:25px;" src="assets/img/icons8-save-100.png">
</button> 
                
        <mat-sidenav-container  #sidenavContainer  class="example-container"  >
                              
                <mat-sidenav style="width: 16%;"  mode="side" opened >

                                <mat-selection-list #linkList >
                                        <mat-list-option *ngFor="let sub of subjects;index as i" 
                                        [class.active]="selectedItem === i" (click)="selectionChanged($event,i)">
                                        <div>
                                                <img src="{{sub.img}}"  class="img-input"  width="30px;"/>
                                                <a mat-list-item  (click)="loadSubject(sub.id)" class="sub-names-tag"> <span class="sub-names">{{sub.subjectName}}</span> </a>
                                        </div>
   
                                        </mat-list-option>
                                </mat-selection-list>


                           
                </mat-sidenav>
               
                        <mat-sidenav-content  style=" width: 84%; padding-bottom: 80px; "> 
                                   <router-outlet ></router-outlet>     
                        </mat-sidenav-content>
            
                
        </mat-sidenav-container>
              
        <button mat-stroked-button class="hideInPrint" (click)="moveToActions()" style="position: fixed; top: auto;bottom:80px;left:30px ; width:180px; height: 45px; ">
                <span style="font-weight: bold; font-size: 16px;">עבור לשלב הבא</span>&nbsp;&nbsp;
                <img style="width:25px;" src="assets/img/left-arrow.png">
            </button> 