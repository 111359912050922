<div mat-dialog-content dir="rtl" style="float:right;">
    <p>האם ברצונך לשלוח את הקישור לכתובת מייל נוספת? </p>
    <mat-form-field style="width:70%; float: right; page-break-after:always;">
      <mat-label>אימייל</mat-label>
      <input matInput #emailInput>
    </mat-form-field>
  </div><br>
  <div mat-dialog-actions style="float:right;">
    <button mat-button (click)="onNoClick()" cdkFocusInitial>לא, תודה</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-button (click)="onOKClick(emailInput.value)" >אישור</button>
  </div>