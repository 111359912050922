import { Component, OnInit } from '@angular/core';
import { MainserviceService } from '../services/mainservice.service'
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.css']
})
export class EmailDialogComponent implements OnInit {

  constructor(private mainService:MainserviceService,
    public dialogRef: MatDialogRef<EmailDialogComponent>) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.mainService.userDetails.addEmail="";
    this.dialogRef.close();
  }

  onOKClick(addEmail): void{
this.mainService.userDetails.addEmail=addEmail;
this.dialogRef.close();
  }

}
