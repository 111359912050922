import { Component, OnInit, ViewChild,NgModule,CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef} from '@angular/core';
import { Router,ActivatedRoute,Params } from '@angular/router';
import { MainserviceService } from '../../services/mainservice.service';
import { FormControl,NgModel,ReactiveFormsModule, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms'; 
import { MatSelect,MatSelectChange,MatSelectModule} from '@angular/material/select';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';

import * as jquery from 'jquery';

@Component({
  selector: 'app-personal-prop-list',
  templateUrl: './personal-prop-list.component.html',
  styleUrls: ['./personal-prop-list.component.css']
})
export class PersonalPropListComponent implements OnInit {
  searchUserForm: FormGroup;

  @ViewChild('subSelect') private subSelect: MatSelect;

  allchecked=false;
  selectedOptions=[];
  currentSelectAllIsChecked=false;

  displayedColumns: string[] = ['property', 'subjects'];
  subjectsList2 = ['מגורים וחיי יום יום', 'תחום חברתי', 'הכנה לצבא', 'תחום בריאות', 'טיולים', 'לימודים','התנדבות בקהילה'];
  subjectsList = ['בחר הכל','מגורים וחיי יום יום', 'תחום חברתי', 'הכנה לצבא', 'תחום בריאות', 'טיולים', 'לימודים','התנדבות בקהילה'];
  

  strengths: Array<{id:number,property:string, subjects:Array<string>}>;
  weaknesses: Array<{id:number,property:string, subjects:Array<string>}>;
  uniqueCharacteristics: Array<{property:string, subjects:Array<string>}>;


  constructor(private router:Router,private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private ReacFormsModule:ReactiveFormsModule, private mainService: MainserviceService) 
  { 
    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const id = params['id'];
      if(id!=undefined)
      {
        this.mainService.isEditMap=true,
        this.mainService.idForEdit=id,
        this.mainService.linkForEdit= this.mainService.currUrl +"?id="+id
        
        if(!(this.mainService.fromRoute))
        {
          this.mainService.getDataById(id).subscribe(data=>(
        
            this.mainService.afterGetDataById(data),
            this.strengths=this.mainService.getStrengths(),
            this.weaknesses=this.mainService.getWeaknesses(),
            this.uniqueCharacteristics=this.mainService.getUniqueCharacteristics()
            )
            );
        }
        else
        {
          this.strengths=this.mainService.getStrengths();
          this.weaknesses=this.mainService.getWeaknesses();
          this.uniqueCharacteristics=this.mainService.getUniqueCharacteristics();
        }

      }
      else
      {
        this.strengths=this.mainService.getStrengths();
          this.weaknesses=this.mainService.getWeaknesses();
          this.uniqueCharacteristics=this.mainService.getUniqueCharacteristics();
      }
    });
   
    }
 

  propListSave()
  {
    this.mainService.moveToAreasOfLife();
    // לבדוק!
    this.mainService.route('prop/0')
    // this.router.navigate(['/',1]);
  }

  saveData()
  {
    this.mainService.saveAllDataInDB();
  }
  
    // functions for strengths
  createStrength()
  {
    if(this.strengths.length>0 && this.strengths[this.strengths.length-1].property!="")
    {
      this.mainService.addStrength();
      this.strengths= this.mainService.getStrengths().concat();
    }
    
  }

  addSubToStrengths(sub,isSelected,id)
  {
  if(sub=="בחר הכל")
    {
      console.log("בחר הכל");
      if (isSelected === true)
      {
        this.mainService.updateSubToStrengths(id,this.subjectsList);
        this.subSelect.options.forEach(sel=>(sel.value!="בחר הכל"? sel.disabled=true:sel.disabled=false));
      }
      else
      {
        this.mainService.updateSubToStrengths(id,[]);
        this.subSelect.options.forEach(sel=>sel.disabled=false);
      }
    }
  else
    {
      if (isSelected === true)
          this.mainService.addSubToStrengths(id,sub);
      else
          this.mainService.delSubToStrengths(id,sub);
    }
    this.strengths=this.mainService.getStrengths();
  }

  isDisable(obj,isSelected) 
  {
        if (obj === 'בחר הכל' ) 
        {
          if(isSelected==true)
            this.currentSelectAllIsChecked=true;
          else
            this.currentSelectAllIsChecked=false;
          return false;
        }
        else 
          if(this.currentSelectAllIsChecked==true)
          {
            return true;
          }
          else
            return false;
  }
  
    // functions for weaknesses
  createWeakness()
  {
    if(this.weaknesses.length>0 && this.weaknesses[this.weaknesses.length-1].property!="")
    {
      this.mainService.addWeakness();
    this.weaknesses= this.mainService.getWeaknesses().concat();
    }
    
  }

  
  addSubToWeaknesses(sub,isSelected,id)
  {
    
  if(sub=="בחר הכל")
    {
      console.log("בחר הכל");
      if (isSelected === true)
      {
        this.mainService.updateSubToWeaknesses(id,this.subjectsList);
        this.subSelect.options.forEach(sel=>(sel.value!="בחר הכל"? sel.disabled=true:sel.disabled=false));
      }
      else
      {
        this.mainService.updateSubToWeaknesses(id,[]);
        this.subSelect.options.forEach(sel=>sel.disabled=false);
      }
    }
  else
    {
      if (isSelected === true)
          this.mainService.addSubToWeaknesses(id,sub);
      else
          this.mainService.delSubToWeaknesses(id,sub);
    }
    this.weaknesses=this.mainService.getWeaknesses();
  }

// functions for uniqueCharacteristics
  createUniqueCharacteristics()
  {
    if(this.uniqueCharacteristics.length>0 && this.uniqueCharacteristics[this.uniqueCharacteristics.length-1].property!="")
    {
      this.mainService.addUniqueCharacteristics();
      this.uniqueCharacteristics= this.mainService.getUniqueCharacteristics().concat();
    }
   
  }

  addSubToUniqueChara(sub,isSelected,prop)
  {
  if(sub=="בחר הכל")
    {
      console.log("בחר הכל");
      if (isSelected === true)
      {
        this.mainService.updateSubToUniqueChara(prop,this.subjectsList);
        this.subSelect.options.forEach(sel=>(sel.value!="בחר הכל"? sel.disabled=true:sel.disabled=false));
      }
      else
      {
        this.mainService.updateSubToUniqueChara(prop,[]);
        this.subSelect.options.forEach(sel=>sel.disabled=false);
      }
    }
  else
    {
      if (isSelected === true)
          this.mainService.addSubToUniqueChara(prop,sub);
      else
          this.mainService.delSubToUniqueChara(prop,sub);
    }
    this.uniqueCharacteristics=this.mainService.getUniqueCharacteristics();
  }


  toggleAllSelection(event: {
      isUserInput: any;
      source: { value: any; selected: any };
    },prop)
    {
      if (event.source.selected === true)
      {
        console.log("true");
      this.searchUserForm.controls.multiSelect1.patchValue([...this.subjectsList, 0]);
      console.log("multiSelect1: "+this.searchUserForm.controls.multiSelect1.value);
      }
      else
      {
        console.log("false");
        this.searchUserForm.controls.multiSelect1.patchValue([]);
        console.log("multiSelect1: "+this.searchUserForm.controls.multiSelect1.value);
      }
    }

    onPrint() {
      window.print();
   }

}
