import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ActionsComponent } from './pages/actions/actions.component';
import { GoalsComponent } from './pages/goals/goals.component';
import { PersonalPropListComponent } from './pages/personal-prop-list/personal-prop-list.component';
import { PersonalPropComponent } from './pages/personal-prop/personal-prop.component';
import { SubjectComponent } from './pages/personal-prop/subject/subject.component';

// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'actions', component: ActionsComponent,children:[
    {path: ':id', component: ActionsComponent }
  ]},
  {path: 'goals', component: GoalsComponent,children:[
    {path: ':id', component: GoalsComponent }
  ]},
  {path: 'proplist', component: PersonalPropListComponent,children:[
    {path: ':id', component: PersonalPropListComponent }
  ]},
  {path: 'prop', component: PersonalPropComponent,children:[
    {path: ':id', component: SubjectComponent }
  ]},
  { path: 'prop/:id', component: PersonalPropComponent ,children:[
    {path: ':id', component: SubjectComponent }
  ]}
  // {path: '*/:id', component: HomeComponent},
  // {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
