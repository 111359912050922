<!-- <button (click)="propListSave()" style="margin-left:15px;">עבור לשלב הבא </button> -->
<button mat-stroked-button class="hideInPrint" (click)="onPrint()" style="margin-left:15px;">
    <img style="width:25px;" src="assets/img/icons8-printer-100.png">
</button> 
<button mat-stroked-button class="hideInPrint" (click)="saveData()" style="margin-left:100px;">
    <img style="width:25px;" src="assets/img/icons8-save-100.png">
</button> 
<mat-sidenav-container  class="example-container"  >
<table  style="float:right; " width="100%">
    <tr>
        
        <td width="30%"><h5 style="float:right; padding-right: 22px;">כוחות וחוזקות</h5></td>
        <td width="1%"></td>
        <td width="30%"><h5 style="float:right; padding-right: 22px;">קשיים ואתגרים</h5></td>
        <!-- <td width="30%"><h5 style="float:right;">מאפיינים יחודיים למכינה</h5></td> -->
    </tr>
<tr >
    <td width="30%" valign = "top" >
        <!-- <form [formGroup]="searchUserForm"> -->
            <table #tblStrength mat-table editable [dataSource]="strengths" class="example-table mat-elevation-z8" >
            
                      <!-- property Column -->
                <ng-container matColumnDef="property">
                        <th mat-header-cell *matHeaderCellDef>מאפיין אישי</th>
                        <td mat-cell *matCellDef="let row" style="text-align:right;">
                                <mat-form-field style="width:90%;">
                                    <textarea   matInput 
                                    cdkTextareaAutosize 
                                    cdkAutosizeMinRows="1" [(ngModel)]="row.property" style="height:30px;box-sizing:content-box;"></textarea>
                                 </mat-form-field> 
                        </td>
                </ng-container>
                
                      <!-- subjects Column -->
                <ng-container matColumnDef="subjects">
                    <th title="באילו תחומי חיים במכינה המאפיין יכול לבוא לידי ביטוי" mat-header-cell *matHeaderCellDef>תחומי חיים במכינה</th>
                    <td mat-cell *matCellDef="let row" style="text-align:right;">
                        <mat-form-field >
                            <mat-select [value]="row.subjects"  #subSelect  class="mat-select"  multiple >
                                <mat-option [disabled]="isDisable(sub,opt.selected)" #opt (click)="addSubToStrengths(sub,opt.selected, row.id)"  *ngFor="let sub of subjectsList;let i=index" [value]="sub">
                                    {{sub}}
                                </mat-option>
                            </mat-select>
                            <!-- (openedChange)="subSelect.value.length==subjectsList.length? subSelect.options.forEach(sel=>(sel.value!='בחר הכל'? sel.disabled=true:sel.disabled=false))" -->
                        </mat-form-field>
                    </td>
                </ng-container>
                

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
              
                    </table>
                <!-- </form> -->
                  <button mat-flat-button color="primary"  style="float:right;"  (click)="createStrength()">+ &nbsp; הוסף חוזקה</button>                   

                  
        
    </td>
    <td></td>
    <td width="30%"  valign = "top">
        <table #tblWeaknesses mat-table editable [dataSource]="weaknesses" class="example-table mat-elevation-z8" >
            
            <!-- property Column -->
      <ng-container matColumnDef="property">
              <th mat-header-cell *matHeaderCellDef>מאפיין אישי</th>
              <td mat-cell *matCellDef="let row" style="text-align:right;">
                      <mat-form-field style="width:90%;">
                          <textarea  matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1" [(ngModel)]="row.property" style="height:30px;box-sizing:content-box;"></textarea>
                       </mat-form-field> 
              </td>
      </ng-container>
      
            <!-- subjects Column -->
      <ng-container matColumnDef="subjects">
          <th title="באילו תחומי חיים במכינה המאפיין יכול לבוא לידי ביטוי" mat-header-cell *matHeaderCellDef>תחומי חיים במכינה</th>
          <td mat-cell *matCellDef="let row" style="text-align:right;">
              <mat-form-field >
                  <mat-select [value]="row.subjects"  #subSelect  class="mat-select"  multiple  >
                        <mat-option [disabled]="isDisable(sub,opt.selected)"  #opt (click)="addSubToWeaknesses(sub,opt.selected, row.id)"  *ngFor="let sub of subjectsList" [value]="sub">
                            {{sub}}
                        </mat-option>
                    </mat-select>
              </mat-form-field>
          </td>
      </ng-container>
      

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    
          </table>
      
        <button mat-flat-button color="primary"  style="float:right;"  (click)="createWeakness()">+ &nbsp; הוסף חולשה</button>
      
         
    </td>
    <!-- <td width="30%" valign = "top">
        <table width="100%" #tblUniqueCharacteristics mat-table editable [dataSource]="uniqueCharacteristics" class="example-table mat-elevation-z8" >
            
           
      <ng-container matColumnDef="property">
              <th mat-header-cell *matHeaderCellDef>מאפיין אישי</th>
              <td mat-cell *matCellDef="let row" style="text-align:right;">
                      <mat-form-field style="width:90%;">
                          <input   matInput  [(ngModel)]="row.property" >
                       </mat-form-field> 
              </td>
      </ng-container>
      
        
      <ng-container matColumnDef="subjects">
          <th title="באילו תחומי חיים במכינה המאפיין יכול לבוא לידי ביטוי" mat-header-cell *matHeaderCellDef>תחומי חיים במכינה</th>
          <td mat-cell *matCellDef="let row" style="text-align:right;">
              <mat-form-field >
                  <mat-select [value]="row.subjects"  #subSelect   class="mat-select"  multiple >
                        <mat-option [disabled]="isDisable(sub,opt.selected)"  #opt (click)="addSubToUniqueChara(sub,opt.selected, row.property)"  *ngFor="let sub of subjectsList" [value]="sub">
                            {{sub}}
                        </mat-option>
                    </mat-select>

              </mat-form-field>
          </td>
      </ng-container>
      

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    
          </table>
      
        <button mat-flat-button color="primary"  style="float:right;"  (click)="createUniqueCharacteristics()">הוסף מאפיין ייחודי</button>
      
    </td> -->
</tr>


</table></mat-sidenav-container>
<button mat-stroked-button class="hideInPrint" (click)="propListSave()"  style="position: fixed; top: auto;bottom:80px;left:30px ; width:180px; height: 45px; ">
    <span style="font-weight: bold; font-size: 16px;">עבור לשלב הבא</span>&nbsp;&nbsp;
    <img style="width:25px;" src="assets/img/left-arrow.png">
</button> 