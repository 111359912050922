import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Params } from '@angular/router';
import { MainserviceService } from '../../services/mainservice.service'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
 import {MatDatepicker} from '@angular/material/datepicker';


@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})

export class ActionsComponent implements OnInit {

  displayedColumns: string[] = ['actionToDo', 'destDate','responsible','isDone'];
  actions:  Array<{id:number,actionToDo:string,destDate:string,responsible:string,isDone:boolean}>;

  constructor(private dateAdapter: DateAdapter<Date>,private mainService:MainserviceService, private activatedRoute:ActivatedRoute) 
  { 
     this.dateAdapter.setLocale('en-GB'); 
     
     window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const id = params['id'];
      if(id!=undefined)
      {
        this.mainService.isEditMap=true,
        this.mainService.idForEdit=id,
        this.mainService.linkForEdit= this.mainService.currUrl +"?id="+id
        
        if(!(this.mainService.fromRoute))
        {
          this.mainService.getDataById(id).subscribe(data=>(
        
            this.mainService.afterGetDataById(data),
            this.actions=this.mainService.getActions()
            )
            );
        }
        else
          this.actions=this.mainService.getActions()

      }
      else
      {
        this.actions=this.mainService.getActions();
      }
    });


  }

 
   
  saveData()
  {
     this.mainService.saveAllDataInDB();
  }

  onPrint() {
     window.print();
  }

}
