

<button mat-stroked-button class="hideInPrint" (click)="onPrint()" style="margin-left:15px;">
    <img style="width:25px;" src="assets/img/icons8-printer-100.png">
</button> 
<button mat-stroked-button class="hideInPrint" (click)="saveData()" style="margin-left:100px; ">
    <img style="width:25px;" src="assets/img/icons8-save-100.png">
</button> 

<mat-sidenav-container  class="example-container"  >
<div class="example-full-width">
    <form action="/action_page.php"   style="float:right;text-align: right;" class="example-full-width">
       <h5>מטרות לשנת המכינה</h5><br>
        <div id="content">
                מטרה היא משהו שאנחנו רוצים להשיג. המטרה יכולה להתייחס לתחום מסוים או להתייחס אל החיים באופן כללי.   <br>
                על מנת להשיג את המטרה הרצויה, עלינו לנקוט בפעולות יזומות ולקבל החלטות שיובילו אותנו אל השגת המטרה. <br>
                בסוף תהליך בניית המפה האישית, תקבלו רשימה של פעולות לביצוע אשר יסייעו לכם בדרך אל השגת המטרה. <br>
        </div><br>
        <h6>שאלות מכוונות לכתיבת המטרות:</h6>
        <ul>
            <li>
                לאן אני רוצה להגיע בסוף שנת המכינה?
            </li>
            <li>
                מה אני רוצה להשיג במהלך ובסוף שנת המכינה?
            </li>
            <li>
                כיצד אני שואף להיתפס על ידי סביבת המכינה כאשר אני במיטבי? 
            </li>
            <li>
                במה ארצה להתגאות שעשיתי בסוף שנת המכינה?
            </li>
            <li>
                מהם הדברים שאצטער אם לא אעשה או אלמד בשנת המכינה?
            </li>
            <li>
                מה עלול לגרום לי להרגיש שפספסתי ושלא מימשתי את הפוטנציאל שלי?
            </li>
        </ul>
        <!-- <div class="form-group"> -->

            <!-- <textarea rows=5 class="form-control" id="goals" [(ngModel)]="goals.goal" name="goals"></textarea> -->

            <table  #tblGoals mat-table editable [dataSource]="goals" style="width: 90%;" >
       
                <ng-container matColumnDef="goal" >
                    <th mat-header-cell *matHeaderCellDef [ngStyle]="{ display: false }" style=" height:90%; padding-right:10px !important;"></th>
                    <td mat-cell *matCellDef="let row" style="text-align:right;padding-right:10px !important;">
                            <mat-form-field style="width:100%;"> 
                                <textarea  style="height:40px;" matInput 
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="row.goal" ></textarea>
                            </mat-form-field> 
                    </td>
                </ng-container>       

            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                
            </table>
            <button mat-flat-button color="primary"  style="float:right;"  (click)="createGoal()"> + &nbsp;הוסף מטרה </button>

    </form>
</div></mat-sidenav-container>
<button mat-stroked-button class="hideInPrint" (click)="nextPage()"  style="position: fixed; bottom:80px;left:30px ; width:180px; height: 45px; z-index: 1;">
    <span style="font-weight: bold; font-size: 16px;">עבור לשלב הבא</span>&nbsp;&nbsp;
    <img style="width:25px;" src="assets/img/left-arrow.png">
</button> 

               
