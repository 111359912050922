import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Params } from '@angular/router';
import { MainserviceService } from '../../../services/mainservice.service'
import { Router } from '@angular/router';


@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent implements OnInit {

  displayedColumns: string[] = ['personalProp', 'support','actionToDo'];
  subInAreasOfLife:  Array<{id:number,subject:number,personalProp:string,support:string,actionToDo:string}>;
  id;
subjectName:string;
 
  constructor(private router:Router,public mainService:MainserviceService, private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute
      .params
      .subscribe(params => {

        this.id =params['id'] || '';
        if(this.id != ''){
          this.subInAreasOfLife = this.mainService.getSubById(this.id);
          this.subjectName=this.mainService.getSubNameById(this.id);
        
       }
    });
  }

  


}
